import { UserApi } from 'api'
import { navigate } from '@reach/router'
import AppStore from 'AppStore'
import { Article, Question, questionDifficulty, questionStatus } from 'types'
import { convertToRaw } from 'draft-js'
export function doNothing() {
  return false
}

export function describeDifficulty(difficulty?: questionDifficulty) {
  if (difficulty === 'easy') return 'Facile'
  if (difficulty === 'medium') return 'Media'
  if (difficulty === 'difficult') return 'Difficile'
  return 'Sconosciuta'
}

export function describeStatus(status?: questionStatus) {
  if (status === 'toReview') return 'Da assegnare'
  if (status === 'inReview') return 'In revisione'
  if (status === 'reviewed') return 'Da modificare'
  if (status === 'updated') return 'Modificata'
  if (status === 'approved') return 'Approvata dal revisore'
  if (status === 'stored') return 'A sistema'
  if (status === 'deleted') return 'Cancellate'
  return 'Creata'
}

export function fixMarkDownJSON(mdJSON: any) {
  for (let i = 0; i < mdJSON.blocks.length; i++) {
    let currentBlock = mdJSON.blocks[i]
    if (currentBlock.entityRanges.length !== 0) {
      for (let j = 0; j < currentBlock.entityRanges.length; j++) {
        let currentEntityRange = currentBlock.entityRanges[j]
        if (mdJSON.entityMap[currentEntityRange.key].type === 'IMAGE') {
          currentBlock.type = 'atomic'
          currentBlock.text = ' '
          currentEntityRange.length = 1

          mdJSON.blocks.splice(i + 1, 0, {
            text: '',
            type: 'unstyled',
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          })
          mdJSON.blocks.splice(i, 0, {
            text: '',
            type: 'unstyled',
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          })
          i += 2
        }
      }
    }
  }
  return mdJSON
}

export const getValueFromDottedKey = (values: any, key: string) => {
  if (!key) return
  if (!key.includes('.') && !key.includes('[')) {
    return values[key]
  }

  if (key.includes('[')) {
    const keys = key.split('[')
    return values[keys[0]][Number(keys[1].substr(0, keys[1].length - 1))]
  }

  const keys = key.split('.')

  const vk1 = values[keys[0]]
  if (keys.length === 2) return vk1 !== undefined ? vk1[keys[1]] : undefined

  const vk2 = vk1[keys[1]]
  if (keys.length === 3) return vk1 !== undefined && vk2 !== undefined ? vk2[keys[2]] : undefined
}

export const setValueWithDottedKey = (values: any, key: string, value: any) => {
  if (key.includes('[')) {
    console.log('entro22')
    console.log(key)
    const keys = key.split('[')
    values[keys[0]][Number(keys[1].substr(0, keys[1].length - 1))] = value
    return values
  }

  if (!key.includes('.')) {
    values[key] = value
    return values
  }

  const keys = key.split('.')
  if (keys.length === 2) {
    let obj: any = {}
    obj[keys[1]] = value
    values[keys[0]] = obj
  }
  if (keys.length === 3) {
    let obj: any = {}
    obj[keys[2]] = value
    values[keys[0]][keys[1]] = { ...values[keys[0]][keys[1]], ...obj }
  }
  return values
}

export function checkWrongQuestion(question: Question): { message: string; toFocus: string } | undefined {
  if (!question.title || question.title.trim() === '') return { message: 'Inserisci il titolo.', toFocus: 'title' }
  if (question.topic_ids?.length === 0) return { message: 'Inserisci una materia', toFocus: 'topic_ids' }
  if (!question.question || question.question.trim() === '')
    return { message: 'Inserisci la domanda', toFocus: 'question' }
  const answersLen = question.answers.length
  if (answersLen < 5) return { message: 'Inserisci tutte le risposte', toFocus: `answers[${answersLen}]` }
  for (let i = 0; i < question.answers.length; i++)
    if (question.answers[i].trim() === '') return { message: 'Inserisci tutte le risposte', toFocus: `answers[${i}]` }
  if (question.explanationCorrect?.trim() === '')
    return { message: 'Inserisci una spiegazione corretta.', toFocus: '__explanationCorrectState' }
  if (question.explanation?.trim() === '')
    return { message: 'Inserisci una spiegazione.', toFocus: '__explanationState' }
  if (question.explanationWrong?.trim() === '')
    return { message: 'Inserisci una spiegazione sbagliata.', toFocus: '__explanationWrongState' }
  const imageUrls = question.image_urls ? question.image_urls.map((url) => AppStore.baseImageUrl + url) : []
  if (checkMissingLink(question.__explanationCorrectState!, imageUrls))
    return { message: 'Rimuovi le immagini cancellate.', toFocus: '__explanationCorrectState' }
  if (checkMissingLink(question.__explanationState!, imageUrls))
    return { message: 'Rimuovi le immagini cancellate.', toFocus: '__explanationState' }
  if (checkMissingLink(question.__explanationWrongState!, imageUrls))
    return { message: 'Rimuovi le immagini cancellate.', toFocus: '__explanationWrongState' }
  return undefined
}

export function checkMissingLink(input: any, urls: string[]) {
  const mdJSON = convertToRaw(input.getCurrentContent())
  const entityKeys = Object.keys(mdJSON.entityMap)
  for (let i = 0; i < entityKeys.length; i++) {
    console.log(mdJSON.entityMap[entityKeys[i]].data.src)
    if (mdJSON.entityMap[entityKeys[i]].data.src && urls.indexOf(mdJSON.entityMap[entityKeys[i]].data.src) === -1)
      return true
  }
  return false
}

export function checkWrongArticle(article: Article): { message: string; toFocus: string } | undefined {
  if (!article.title || article.title.trim() === '') return { message: 'Inserisci il titolo.', toFocus: 'title' }
  if (!article.author || article.author.trim() === '') return { message: 'Inserisci un autore.', toFocus: 'author' }
  return undefined
}

export const actions = [
  {
    value: 'WEB_IN_APP',
    label: 'Apri pagina web in app',
  },
  {
    value: 'VIDEO_IN_APP',
    label: 'Apri un video in app',
  },
  {
    value: 'WEB_EXT',
    label: 'Apri pagina web esterna',
  },
  {
    value: 'ALERT',
    label: 'Alert in app',
  },
  {
    value: 'NAVIGATE',
    label: 'Navigazione in app',
  },
]

export const appScreens = [
  { value: 'StartChart', label: 'StartChart' },
  { value: 'StartNews', label: 'StartNews' },
  { value: 'StartRevision', label: 'StartRevision' },
  { value: 'Profile', label: 'Profile' },
  { value: 'FAQ', label: 'FAQ' },
  { value: 'Guide', label: 'Guide' },
  { value: 'ChangePersonalData', label: 'ChangePersonalData' },
  { value: 'Purchase', label: 'Purchase' },
  { value: 'RevisionStack', label: 'RevisionStack' },
  { value: 'ChartStack', label: 'ChartStack' },
  { value: 'QuizStack', label: 'QuizStack' },
  { value: 'NewsStack', label: 'NewsStack' },
  { value: 'Settings', label: 'Settings - promotions: true/false' },
  { value: 'TabNavigation', label: 'TabNavigation' },
  { value: 'Forgot', label: 'Forgot' },
  { value: 'SignUp', label: 'SignUp' },
  { value: 'Login', label: 'Login' },
  { value: 'ChangePassword', label: 'ChangePassword' },
  { value: 'SavedQuestions', label: 'SavedQuestions' },
  { value: 'CreateQuiz', label: 'CreateQuiz' },
  { value: 'SearchScreen', label: 'SearchScreen' },
  { value: 'PointsDetailsQuiz', label: 'PointsDetailsQuiz' },
  { value: 'QuestionDetail', label: 'QuestionDetail' },
  { value: 'CreateRevision', label: 'CreateRevision' },
  { value: 'RevisionDetail', label: 'RevisionDetail' },
]

export const userTypes = [
  { value: 3, label: 'Utente' },
  { value: 2, label: 'Admin' },
  { value: 1, label: 'SuperAdmin' },
]

export const logOut = async () => {
  try {
    const refreshToken = AppStore.user?.refreshToken!
    await UserApi.logout(refreshToken)
  } catch (e) {}
  AppStore.clearAsyncStorage()
  await AppStore.saveUpdateCode()
  await AppStore.changeNotification({ message: 'Logout effettuato correttamente.', type: 'success' })
  navigate('/login')
}

export const littleImage =
  'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0iIzAwMCIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTQuNzQxIDBILjI2Qy4xMTYgMCAwIC4xMzYgMCAuMzA0djEzLjM5MmMwIC4xNjguMTE2LjMwNC4yNTkuMzA0SDE0Ljc0Yy4xNDMgMCAuMjU5LS4xMzYuMjU5LS4zMDRWLjMwNEMxNSAuMTM2IDE0Ljg4NCAwIDE0Ljc0MSAwem0tLjI1OCAxMy4zOTFILjUxN1YuNjFoMTMuOTY2VjEzLjM5eiIvPjxwYXRoIGQ9Ik00LjEzOCA2LjczOGMuNzk0IDAgMS40NC0uNzYgMS40NC0xLjY5NXMtLjY0Ni0xLjY5NS0xLjQ0LTEuNjk1Yy0uNzk0IDAtMS40NC43Ni0xLjQ0IDEuNjk1IDAgLjkzNC42NDYgMS42OTUgMS40NCAxLjY5NXptMC0yLjc4MWMuNTA5IDAgLjkyMy40ODcuOTIzIDEuMDg2IDAgLjU5OC0uNDE0IDEuMDg2LS45MjMgMS4wODYtLjUwOSAwLS45MjMtLjQ4Ny0uOTIzLTEuMDg2IDAtLjU5OS40MTQtMS4wODYuOTIzLTEuMDg2ek0xLjgxIDEyLjE3NGMuMDYgMCAuMTIyLS4wMjUuMTcxLS4wNzZMNi4yIDcuNzI4bDIuNjY0IDMuMTM0YS4yMzIuMjMyIDAgMCAwIC4zNjYgMCAuMzQzLjM0MyAwIDAgMCAwLS40M0w3Ljk4NyA4Ljk2OWwyLjM3NC0zLjA2IDIuOTEyIDMuMTQyYy4xMDYuMTEzLjI3LjEwNS4zNjYtLjAyYS4zNDMuMzQzIDAgMCAwLS4wMTYtLjQzbC0zLjEwNC0zLjM0N2EuMjQ0LjI0NCAwIDAgMC0uMTg2LS4wOC4yNDUuMjQ1IDAgMCAwLS4xOC4xTDcuNjIyIDguNTM3IDYuMzk0IDcuMDk0YS4yMzIuMjMyIDAgMCAwLS4zNTQtLjAxM2wtNC40IDQuNTZhLjM0My4zNDMgMCAwIDAtLjAyNC40My4yNDMuMjQzIDAgMCAwIC4xOTQuMTAzeiIvPjwvZz48L3N2Zz4='
